import * as React from "react";
import { useLoaderContext } from "./reducer/Context";
import "./Loader.css";
import LoadingIcon from "./LoadingIcon";

const Loader = ({
  children,
  classNames = "",
  styles = {},
  visible,
  centered = true,
}) => {
  const { message, percent, loading } = useLoaderContext();
  const [_visible, _setVisible] = React.useState(true);

  React.useEffect(() => {
    if (visible) {
      _setVisible(visible);
    } else {
      _setVisible(loading);
    }
  }, [loading, visible]);

  const _styles = { ...styles, backgroundColor: "transparent" };
  return (
    <React.Fragment>
      {_visible ? (
        <LoadingIcon
          percent={percent}
          message={message}
          centered={centered}
          classNames={classNames}
        />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Loader;
