import React, { useContext, useEffect, useReducer } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PageContext, {
  usePageContext,
} from "@bit/hlouzek.rhplus.page/dist/page/redux/PageContext";
import PageReducer from "@bit/hlouzek.rhplus.page/dist/page/redux/PageReducer";
import configureStore, { history } from "./store";
import App from "./containers/App";
import { LoaderContainer } from '@bit/hlouzek.rhplus.loader/index';

// const pusherConfig = require(`${process.env.REACT_APP_PUSHER}`);

// import {LicenseManager} from "ag-grid-enterprise";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
// LicenseManager.setLicenseKey("MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10");

export const store = configureStore();

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);

  return (
    // <LoaderContainer
    //   config={pusherConfig}
    //   channel={"RHPLUS_SHORTAGES_LOADING_CHANNEL"}
    // >
      <PageContext.Provider value={{ pageState, pageDispatch }}>
        <App {...props} />
      </PageContext.Provider>
    // </LoaderContainer>
  );
};

export default function MainApp() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={Apps} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}
