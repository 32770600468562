import * as React from 'react';

export const init = {
  key: '',
  message: '',
  percent: undefined,
  loading: true,
  setMessage: () => {},
  setLoading: () => {}
}

export const LoaderContext = React.createContext(init);
export const useLoaderContext = () => React.useContext(LoaderContext);